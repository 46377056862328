<template>
  <div class="body">
    <img src="@/assets/img/guide/hospitalCulture/bg.png" width="100%" />
    <div class="icon-title">
      <img src="@/assets/img/guide/hospitalCulture/icon.png" alt="" />
      <span>医院文化</span>
    </div>
    <div>
      <van-collapse v-model="activeNames" @change="handleChange">
        <van-collapse-item name="1" :value="value1">
          <template #title>
            <div>
              <div class="icon-title">
                <img src="@/assets/img/guide/hospitalCulture/icon.png" alt="" />
                <span>院徽及释义</span>
              </div>
            </div>
          </template>
          <div class="content">
            <img src="@/assets/img/guide/hospitalCulture/logo.png" alt="" />
            <p class="content-text">
              图案以变形十字、数字“2”和心形组成，由橄榄枝相拥，红蓝两色为主，金色点缀，既突出医院识别，又增添人文精神，象征着吉祥喜庆，以及对人生命、健康、希望、和平、爱的执着追求。以“2”分割十字，既完善医院识别，又能联想河南省第二人民医院。
            </p>
          </div>
        </van-collapse-item>
        <van-collapse-item name="2" :value="value2">
          <template #title>
            <div>
              <div class="icon-title">
                <img src="@/assets/img/guide/hospitalCulture/icon.png" alt="" />
                <span>院歌</span>
              </div>
            </div>
          </template>
          <div class="yuange">
            <img src="@/assets/img/guide/hospitalCulture/yuange.png" alt="" />
            <img src="@/assets/img/guide/hospitalCulture/yuange1.png" alt="" />
          </div>
        </van-collapse-item>
      </van-collapse>
      <!-- <div class="content">
      <span v-html="contents.content"></span>
    </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "hospitalCulture",
  data() {
    return {
      activeNames: ["1", "2"],
      value1: "收起",
      value2: "收起",
      contents: "",
    };
  },
  methods: {
    handleChange(val) {
      if (val.indexOf("1") == "-1") {
        this.value1 = "展开";
      } else {
        this.value1 = "收起";
      }
      if (val.indexOf("2") == "-1") {
        this.value2 = "展开";
      } else {
        this.value2 = "收起";
      }
    },
    getData() {
      let postData = {
        parentId: this.$route.query.id,
      };
      this.$http.get("/api/content/infoByParentId", postData).then((res) => {
        this.contents = res.data.contents.find(
          (item) => item.title == "医院文化"
        );
      });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style scoped>
.icon-title {
  position: absolute;
  left: 0.8rem;
  top: 0.3rem;
}
.icon-title img {
  width: 0.6rem;
  height: 0.6rem;
}
.icon-title span {
  font-size: 0.45rem;
  margin-left: 0.2rem;
  position: relative;
  top: -5px;
}
.van-collapse {
  text-align: left;
}
.content {
  text-align: center;
}
.content img {
  margin: 0.2rem auto;
  height: 4.5rem;
  width: 4.5rem;
}
.content .content-text {
  text-indent: 0.8rem;
  font-size: 0.45rem;
  line-height: 30px;
  text-align: left;
  color: rgba(87, 89, 101, 1);
}
.yuange img {
  width: 9rem;
}
/* .content img {
  width: 99%;
  height: auto;
}
.content {
  font-size: 0.4rem;
  width: 97%;
  margin: auto;
} */
</style>
